export const awards = {
  title: "Recognition",
  links: [
    { key: 0, label: "Road Safety Hackathon by World Bank", url: "" },
    { key: 1, label: "GE Thinkathon", url: "" },
    { key: 2, label: "Rise Hackathon by Barclays", url: "" },
    { key: 3, label: "Hack4People by Vigilance Department", url: "" },
    {
      key: 4,
      label: "SingularityU's Global Impact Challenge",
      url:
        "https://medium.com/singularityu/ajna-wins-global-impact-challenge-india-2018-d2af5ef8b038",
      target: "_blank",
    },
  ],
}
