import React from "react"
import { LinkSection } from "./LinkSection"
import { Wrapper, Container } from "./sc"

import { sections } from "../../../data/index"

export function Links() {
  return (
    <Wrapper>
      <Container>
        {sections.map((section, index) => (
          <LinkSection key={index} {...section} />
        ))}
      </Container>
    </Wrapper>
  )
}
