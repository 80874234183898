import React from "react"

import { description } from "../../data/description"
import { Logo } from "../../glyphs/Logo"

export function Header() {
  return (
    <div className="f white open-sans" style={{ maxWidth: "22rem" }}>
      <Logo color="white" size={80} />
      <div className="fv ml3">
        <div className="f3 mb2">Qucentis</div>
        <div
          style={{ letterSpacing: "0.03rem" }}
          className="lh-copy white-70 f5"
        >
          {description}
        </div>
      </div>
    </div>
  )
}
