import styled from "styled-components"

export const Wrapper = styled.div.attrs({
  className: "",
})``

export const Container = styled.div.attrs({
  className: "f flex-wrap",
})`
  @media (max-width: 30rem) {
    flex-direction: column;
  }
`
