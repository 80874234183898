export const elsewhere = {
  title: "Elsewhere",
  links: [
    {
      key: 0,
      label: "Github",
      url: "https://github.com/qucentis",
      target: "_blank",
    },
    {
      key: 1,
      label: "Twitter",
      url: "https://twitter.com/qucentis",
      target: "_blank",
    },
    {
      key: 2,
      label: "Facebook",
      url: "https://facebook.com/qucentis",
      target: "_blank",
    },
    {
      key: 3,
      label: "LinkedIn",
      url: "https://www.linkedin.com/company/qucentis/",
      target: "_blank",
    },
    {
      key: 4,
      label: "BIGOrg",
      url: "",
      target: "_blank",
    },
  ],
}
