import React from "react"
import ReactDOM from "react-dom"
import "tachyons"

import "./styles/q.css"
import "./styles/fonts/opensans.css"
import "./styles/custom.css"

import { App } from "./App"
import registerServiceWorker from "./registerServiceWorker"

ReactDOM.render(<App />, document.getElementById("root"))
registerServiceWorker()
