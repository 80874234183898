export const team = {
  title: "Team",
  links: [
    {
      key: 0,
      label: "Nikhil Mohan",
      url: "https://github.com/nikhil-mohan",
      target: "_blank",
    },
    {
      key: 1,
      label: "Rakesh BS",
      url: "https://github.com/rakeshbs",
      target: "_blank",
    },
    {
      key: 2,
      label: "Jikku Jose",
      url: "https://twitter.com/jikkujose",
      target: "_blank",
    },
  ],
}
