export const domains = {
  title: "Domains",
  links: [
    {
      key: 0,
      label: "Blockchain",
      url: "https://en.wikipedia.org/wiki/Blockchain",
      target: "_blank",
    },
    {
      key: 1,
      label: "UI/UX",
      url: "https://en.wikipedia.org/wiki/User_experience_design",
      target: "_blank",
    },
    {
      key: 2,
      label: "Data Science",
      url: "https://en.wikipedia.org/wiki/Data_science",
      target: "_blank",
    },
  ],
}
