import React from "react"
import { Home } from "./Home"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"

const basename = process.env["NODE_ENV"] === "production" ? "/" : ""

const NotFound = () => <div className="sans-serif">Not Found</div>
const CenteredHome = () => (
  <div className="min-vh-100 f bg-qucentis-blue ca">
    <Home />
  </div>
)

export function App() {
  return (
    <Router basename={basename}>
      <Routes>
        <Route path="/" element={<CenteredHome />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  )
}
