import styled from "styled-components"

export const Wrapper = styled.div.attrs({
  className: "mt5 mr5",
})``

export const Container = styled.div.attrs({
  className: "",
})``

export const Title = styled.div.attrs({
  className: "open-sans f4 white-80 ttu mb3 tracked",
})``

export const LinkContainer = styled.div.attrs({
  className: "",
})`
  letter-spacing: 0.04rem;
  line-height: 1.25rem;
  margin-bottom: 0.4rem;

  @media (max-width: 30rem) {
    margin-bottom: 0.6rem;
  }
`
