import React from "react"

export function Logo({ size = null, scale = 1, color = "black" }) {
  return (
    <svg
      width={size || 41 * scale}
      height={size || 80 * scale}
      viewBox="0 0 41 80"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <g transform="translate(-203 -127)" fill={color}>
          <g transform="translate(203 127)">
            <g>
              <g>
                <path d="M35.7747847,72.2270052 C31.1149779,72.3240845 28.6879952,71.838688 26.9405677,69.7029432 C33.2507227,68.5379915 40.919988,63.5869468 40.919988,51.5491126 L40.919988,20.3866547 C40.919988,7.86342395 33.7361192,0 20.7274919,0 C8.30134052,0 0.146678636,6.60139295 0.146678636,20.2895754 L0.146678636,51.5491126 C0.146678636,65.4314536 10.2429267,69.8000225 17.912192,70.3824984 C20.3391747,76.1101775 25.2902194,80.1875085 34.9010709,79.9933499 L35.7747847,72.2270052 Z M32.085771,51.354954 C32.085771,55.9176815 30.3383434,61.2570434 24.9989815,61.5482813 C24.8048229,59.8979331 24.9019022,58.2475848 24.9019022,56.1118401 L24.9019022,52.616985 L15.9706059,52.616985 L15.9706059,56.2089194 C15.9706059,58.2475848 15.8735265,59.8979331 16.1647645,61.6453606 C10.9224818,61.451202 8.98089567,55.2381263 8.98089567,51.4520333 L8.98089567,20.0954168 C8.98089567,13.6881824 11.9903542,9.22253427 20.5333333,9.22253427 C29.1733918,9.22253427 32.085771,14.6589755 32.085771,20.0954168 L32.085771,51.354954 Z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
