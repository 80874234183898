import React from "react"
import { Wrapper, Container, Title, LinkContainer } from "./sc"

export function LinkSection({ title, links }) {
  return (
    <Wrapper>
      <Container>
        <Title>{title}</Title>
        <div className="">{links.map(link => <Link {...link} />)}</div>
      </Container>
    </Wrapper>
  )
}

function Link({ label, url, target }) {
  const params = {
    href: url,
    target,
  }

  const linkClassName = "link white-90 open-sans f5 hover-white"
  const spanClassName = "link white-50 open-sans f5 usn"

  return (
    <LinkContainer>
      {url ? (
        <a className={linkClassName} {...params}>
          {label}
        </a>
      ) : (
        <span className={spanClassName}>{label}</span>
      )}
    </LinkContainer>
  )
}
