export const products = {
  title: "Products",
  links: [
    {
      key: 0,
      label: "Azani",
      url:
        "https://docs.google.com/presentation/d/1Vt5oHSS9LXVInaqk9SkaG5i6in-9jMFKEtvMDb832wY/edit?usp=sharing",
      target: "_blank",
    },
    {
      key: 4,
      label: "Achal",
      url: "https://www.youtube.com/watch?v=MV-nTGQMRxo",
      target: "_blank",
    },
    {
      key: 1,
      label: "Ayu",
      url:
        "https://docs.google.com/presentation/d/19LsCx5G2vQfCiv4wB64BEggqsIbmvQAc5JY1IpDf5jk/edit?usp=sharing",
      target: "_blank",
    },
    {
      key: 2,
      label: "Ajna",
      url:
        "https://docs.google.com/presentation/d/1pNa9wsVBPKyr5HqEzNEyWnVTZZ1x0wXrI8eEsaNfZAI/edit?usp=sharing",
    },
    {
      key: 3,
      label: "Ajira",
      url:
        "https://docs.google.com/presentation/d/1E2fSwdrvfv_V_niswkrAf5L0tS2iaxFqJXRVl8Xs7Zg/edit?usp=sharing",
      target: "_blank",
    },
  ],
}
