import React from "react"

import { Header } from "./Header"
import { Links } from "./Links"
import { Wrapper, Container } from "./sc"

export function Home() {
  return (
    <Wrapper>
      <Container>
        <Header />
        <Links />
      </Container>
    </Wrapper>
  )
}
